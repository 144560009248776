// package imports
import { useContext, useState } from "react";

// interface imports
import {
  Box,
  Flex,
  Image,
  Text,
  Input,
  InputGroup,
  Button,
  InputLeftAddon,
  PinInputField,
  PinInput,
  HStack,
  useToast,
} from "@chakra-ui/react";

// utility imports
import { sendOTPtoWhatsapp, verifyOTP } from "../../api/auth";
import AuthContext from "../../utils/auth";

const ConnectWA = () => {
  const toast = useToast();
  const authContext = useContext(AuthContext);

  // number and form states
  const [wa, setWA] = useState();
  const [otp, setOTP] = useState();
  const [toggle, setToggle] = useState(false);

  // send OTP handler
  const sendOTPHandler = async () => {
    try {
      await sendOTPtoWhatsapp(wa, authContext.token);
      setToggle(!toggle);
    } catch (err) {
      console.log(err);
      toast({
        title: "An error occurred.",
        description: err.response.data.detail,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  // verify OTP handler
  const verifyOTPHandler = async () => {
    try {
      await verifyOTP(otp, authContext.token);
      window.location = "/";
    } catch (err) {
      toast({
        title: "An error occurred.",
        description: err.response.data.detail,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex width="100vw" minHeight="100vh" bg="#040F13">
      <Flex
        flexDir="column"
        m="auto"
        width={["350px", "350px", "350px", "600px", "600px"]}
        bg="#1A2327"
        p={["30px", "30px", "30px", "50px", "50px"]}
        borderRadius="20px"
      >
        <Image
          src="https://i.imgur.com/0Rz2lMQ.png"
          width={["130px", "130px", "130px", "180px", "180px"]}
          height="fit-content"
        />
        {!toggle ? (
          <Flex flexDir="column">
            <Box mt="30px">
              <Text
                color="white"
                as="span"
                fontSize="32px"
                fontWeight="600"
                letterSpacing="-0.5px"
                lineHeight="1"
                opacity="0.8"
              >
                connect your whatsapp
              </Text>
            </Box>
            <Flex flexDir="row" mt="20px">
              <InputGroup size="lg" mr="10px">
                <InputLeftAddon
                  color="white"
                  bg="none"
                  fontWeight="600"
                  fontSize="24px"
                  children="+91"
                />
                <Input
                  type="tel"
                  color="white"
                  fontSize="24px"
                  fontWeight="600"
                  placeholder="phone number"
                  onChange={(e) => setWA(e.target.value)}
                />
              </InputGroup>
            </Flex>
            <Button
              bg="white"
              color="#1A2327"
              size="lg"
              fontSize="20px"
              fontWeight="600"
              width="fit-content"
              mt="55px"
              onClick={sendOTPHandler}
            >
              submit
            </Button>
          </Flex>
        ) : (
          <Flex flexDir="column">
            <Box mt="30px">
              <Text
                color="white"
                as="span"
                fontSize="32px"
                fontWeight="600"
                letterSpacing="-0.5px"
                lineHeight="1"
                opacity="0.8"
              >
                verify otp
              </Text>
            </Box>
            <Flex flexDir="row" mt="20px">
              <HStack>
                <PinInput otp onChange={(e) => setOTP(e)}>
                  <PinInputField
                    color="white"
                    fontSize="24px"
                    fontWeight="600"
                  />
                  <PinInputField
                    color="white"
                    fontSize="24px"
                    fontWeight="600"
                  />
                  <PinInputField
                    color="white"
                    fontSize="24px"
                    fontWeight="600"
                  />
                  <PinInputField
                    color="white"
                    fontSize="24px"
                    fontWeight="600"
                  />
                </PinInput>
              </HStack>
            </Flex>
            <Text color="white" mt="10px">
              Please enter the OTP sent on WhatsApp to {wa}.
            </Text>
            <Button
              bg="white"
              color="#1A2327"
              size="lg"
              fontSize="20px"
              fontWeight="600"
              width="fit-content"
              mt="30px"
              onClick={verifyOTPHandler}
            >
              submit
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ConnectWA;
