// package imports
import { useRef } from "react";

// interface imports
import {
  Flex,
  Text,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/react";

const HelpDesk = () => {
  // render function
  return (
    <Flex flexDir="column" mt="50px" mb="50px">
      <Text
        fontSize={["14px", "14px", "14px", "16px", "16px"]}
        fontWeight="600"
        color="white"
        opacity="0.8"
        textTransform="uppercase"
      >
        help desk
      </Text>
      <Flex flexDir="row" flexWrap="wrap" mt="12px">
        <Button
          width="fit-content"
          fontSize={["16px", "16px", "16px", "18px", "18px"]}
          fontWeight="600"
          p="22px 17px"
          borderRadius="7px"
          _hover={{ opacity: "0.8" }}
          bg="#1A2327"
          color="white"
          mr={["10px", "10px", "10px", "15px", "15px"]}
          mb={["10px", "10px", "10px", "0", "0"]}
          as="a"
          href="/docs"
          target="_blank"
          rel="noreferrer"
        >
          Read the Docs
        </Button>
        <Button
          width="fit-content"
          fontSize={["16px", "16px", "16px", "18px", "18px"]}
          fontWeight="600"
          p="22px 17px"
          borderRadius="7px"
          _hover={{ opacity: "0.8" }}
          bg="#1A2327"
          color="white"
          mr={["10px", "10px", "10px", "15px", "15px"]}
          mb={["10px", "10px", "10px", "0", "0"]}
          as="a"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdNB5uEwAMhcPExyxVV84hUppPjdqhy0ZNT4MTRuWBDDQDvog/viewform"
          target="_blank"
          rel="noreferrer"
        >
          Give feedback
        </Button>
        <Button
          width="fit-content"
          fontSize={["16px", "16px", "16px", "18px", "18px"]}
          fontWeight="600"
          p="22px 17px"
          borderRadius="7px"
          _hover={{ opacity: "0.8" }}
          bg="#1A2327"
          color="white"
          mr={["10px", "10px", "10px", "15px", "15px"]}
          mb={["10px", "10px", "10px", "0", "0"]}
          as="a"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfORnWkDkworawuVcmrFHFVvqJcYvKnrJMq0VKouDUSA_ccJw/viewform"
          target="_blank"
          rel="noreferrer"
        >
          Request invite codes
        </Button>
        <DeleteMyAccount />
      </Flex>
    </Flex>
  );
};

// Delete My Account button and alert dialog
const DeleteMyAccount = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const deleteAccountHandler = () => {
    onClose();
    // write delete function here
    // 1) API call to delete account
    // 2) logout user and clear broswer memory
  };

  return (
    <>
      <Button
        width="fit-content"
        fontSize={["16px", "16px", "16px", "18px", "18px"]}
        fontWeight="600"
        p="22px 17px"
        borderRadius="7px"
        _hover={{ opacity: "0.8" }}
        bg="#C53738"
        color="white"
        mr={["10px", "10px", "10px", "15px", "15px"]}
        mb={["10px", "10px", "10px", "0", "0"]}
        onClick={onOpen}
      >
        Delete my account
      </Button>
      <AlertDialog
        motionPreset="scale"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="lg"
      >
        <AlertDialogOverlay />

        <AlertDialogContent bg="#1A2327" p="10px">
          <AlertDialogHeader color="white" fontSize="24px">
            Delete Account?
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody
            color="whiteAlpha.900"
            fontSize="18px"
            lineHeight="1.5"
          >
            Are you sure that you want to delete your account? All chat data
            will be deleted from our servers and you'll need to sign up again to
            chat with the clutch chatbot.
          </AlertDialogBody>
          <AlertDialogFooter mt="20px">
            <Button
              ref={cancelRef}
              onClick={onClose}
              bg="#394247"
              color="whiteAlpha.900"
              fontSize={["16px", "16px", "16px", "18px", "18px"]}
              fontWeight="600"
              p="22px 17px"
              borderRadius="7px"
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              fontSize={["16px", "16px", "16px", "18px", "18px"]}
              fontWeight="600"
              p="22px 17px"
              borderRadius="7px"
              onClick={deleteAccountHandler}
            >
              Yes, delete my account
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default HelpDesk;
