// package imports
import { Link } from "react-router-dom";
import StyledMarkdown from "../../components/docs/StyledMarkdown";

// interface imports
const { Flex, Image } = require("@chakra-ui/react");

const Legal = ({ markdown }) => {
  // render function
  return (
    <Flex width="100vw" minHeight="100vh" bg="#040F13" color="white">
      <Flex
        flexDir="column"
        width="80vw"
        maxWidth="1000px"
        m="80px auto"
        className=""
      >
        <Link to="/">
          <Image
            src="https://i.imgur.com/0Rz2lMQ.png"
            width={["130px", "130px", "130px", "130px", "130px"]}
            height="fit-content"
          />
        </Link>
        <StyledMarkdown children={markdown} />
      </Flex>
    </Flex>
  );
};

export default Legal;
