// package imports
import { useContext, useEffect, useState } from "react";

// interface imports
import {
  Flex,
  Image,
  Text,
  Button,
  Icon,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import Connection from "../../components/dashboard/Connection";
import HelpDesk from "../../components/dashboard/HelpDesk";
import { SiGmail, SiWhatsapp } from "react-icons/si";
import { BiPowerOff } from "react-icons/bi";

// utility imports
import AuthContext from "../../utils/auth";
import { getUserDetails } from "../../api/dashboard";
import { formatPhoneNumber } from "../../utils/functions";

const Dashboard = () => {
  const [userData, setUserData] = useState(); // state variable to store user data
  const authContext = useContext(AuthContext); // access to auth context provider
  const toast = useToast(); // initialise toasts

  // API call to get user data
  const getDashboardInfo = async () => {
    try {
      const response = await getUserDetails(authContext.token);
      setUserData(response);
    } catch (err) {
      toast({
        title: "An error occurred.",
        description: err.response.data.detail,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  // useEffect to get user data
  useEffect(() => {
    getDashboardInfo();
    // eslint-disable-next-line
  }, []);

  // render function
  return (
    <Flex width="100vw" minHeight="100vh" bg="#040F13">
      {userData ? (
        <Flex
          flexDir="column"
          m="60px auto"
          width={["80vw", "80vw", "80vw", "1100px", "1100px"]}
        >
          <Flex
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Image
              src="https://i.imgur.com/0Rz2lMQ.png"
              width={["130px", "130px", "130px", "130px", "130px"]}
              height="fit-content"
            />
            <Button
              size="sm"
              bg="#1A2327"
              color="white"
              borderRadius="10px"
              leftIcon={<Icon as={BiPowerOff} />}
              mr="5px"
              _hover={{ opacity: 0.8 }}
              onClick={authContext.logout}
            >
              Logout
            </Button>
          </Flex>
          <Text
            mt="40px"
            fontSize={["32px", "32px", "32px", "48px", "48px"]}
            fontWeight="600"
            color="white"
          >
            welcome, {userData.name.split(" ")[0].toLowerCase()}.
          </Text>
          <Connection
            connectionTitle="CONNECTED WHATSAPP NUMBER"
            connectionLogo={SiWhatsapp}
            connectionDesc={null}
            connectionId={formatPhoneNumber(userData.whatsapp_number)}
          />
          <Connection
            connectionTitle="ACTIVE CONNECTIONS"
            connectionLogo={SiGmail}
            connectionDesc="By connecting your email, you’re able to automate composing and sending emails using clutch."
            connectionId={userData.email}
          />
          <HelpDesk />
        </Flex>
      ) : (
        <Spinner m="auto" color="white" />
      )}
    </Flex>
  );
};

export default Dashboard;
