// package imports
import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "", // auth token
  isLoggedIn: false, // login status boolean
  login: (token) => {}, // login function
  logout: () => {}, // logout function
  name: "", // user name
  setName: (name) => {}, // set user name function
  userVerified: false, // waitlist status boolean
  verifyUser: () => {}, // verify waitlist status boolean
});

export const AuthContextProvider = (props) => {
  // login status
  // --------
  const initialToken = localStorage.getItem("token");
  const [token, setToken] = useState(initialToken);

  const userIsLoggedIn = !!token;

  const loginHandler = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
  };

  const logoutHandler = () => {
    setToken(null);
    localStorage.clear();
  };

  // waitlist status
  // --------
  const initialUserVerified = localStorage.getItem("userVerified");
  const [userVerified, setUserVerified] = useState(initialUserVerified);

  const verifyUserHandler = () => {
    setUserVerified(true);
    localStorage.setItem("userVerified", true);
  };

  // user details
  // --------
  const initialName = localStorage.getItem("name");
  const [name, setName] = useState(initialName);

  const nameHandler = (name) => {
    setName(name);
    localStorage.setItem("name", name);
  };

  // context export
  // ---------
  const contextValue = {
    token: token, // auth token
    isLoggedIn: userIsLoggedIn, // login status boolean
    login: loginHandler, // login function
    logout: logoutHandler, // logout function
    name: name, // user name
    setName: nameHandler, // set user name function
    userVerified: userVerified, // waitlist status boolean
    verifyUser: verifyUserHandler, // verify waitlist status boolean
  };

  // render function
  // -------
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
