// package imports
import { useEffect, useState, useRef, useContext } from "react";
import { useGoogleLogin } from "@react-oauth/google";

// interface imports
import { Button, Icon, useToast } from "@chakra-ui/react";
import { SiGoogle } from "react-icons/si";

// utility imports
import AuthContext from "../../utils/auth";
import { postAccessCode } from "../../api/auth";

const LoginWithGoogle = () => {
  const [accessCode, setAccessCode] = useState(); // store for temporary OAuth 2.0 access code
  const authContext = useContext(AuthContext); // access to auth context provider
  const isMounted = useRef(false); // check component mount for useEffect
  const toast = useToast(); // initialise toasts

  // google authentication handler
  const loginHandler = useGoogleLogin({
    onSuccess: (codeResponse) => setAccessCode(codeResponse.access_token),
  });

  // useEffect to fire request to backend
  useEffect(() => {
    if (isMounted.current) {
      updateAccessCode();
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [accessCode]);

  // clutch API authentication handler
  const updateAccessCode = async () => {
    try {
      const response = await postAccessCode(accessCode);
      authContext.setName(response.name);
      authContext.login(response.token);
      if (response.wa_verified) authContext.verifyUser();
    } catch (err) {
      toast({
        title: err.message,
        description: "An error occurred. Please try again.",
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  // render function
  return (
    <Button
      leftIcon={
        <Icon
          as={SiGoogle}
          boxSize={["15px", "15px", "15px", "20px", "20px"]}
          mr="25px"
          mb="2px"
        />
      }
      width="100%"
      height={["50px", "50px", "50px", "70px", "70px"]}
      bg="white"
      mt="30px"
      fontWeight="600"
      fontSize={["18px", "18px", "18px", "22px", "22px"]}
      letterSpacing="-0.5px"
      borderRadius={["10px", "10px", "10px", "15px", "15px"]}
      onClick={loginHandler}
    >
      Continue with Google
    </Button>
  );
};

export default LoginWithGoogle;
