// package imports
import { Box, Flex, Image, Text } from "@chakra-ui/react";

// interface imports
import LoginWithGoogle from "../../components/auth/LoginWithGoogle";

const Login = () => {
  // render function
  return (
    <Flex width="100vw" minHeight="100vh" bg="#040F13">
      <Flex
        flexDir="column"
        m="auto"
        maxWidth={["300px", "300px", "300px", "600px", "600px"]}
      >
        <Image
          src="https://i.imgur.com/0Rz2lMQ.png"
          width={["130px", "130px", "130px", "180px", "180px"]}
          height="fit-content"
        />
        <Box mt="30px">
          <Text
            color="white"
            as="span"
            fontSize={["32px", "32px", "32px", "48px", "48px"]}
            fontWeight="600"
            letterSpacing="-0.5px"
            lineHeight="1"
            opacity="0.8"
          >
            introducing clutch.{" "}
          </Text>
          <Text
            color="white"
            as="span"
            fontSize={["32px", "32px", "32px", "48px", "48px"]}
            fontWeight="600"
            letterSpacing="-0.5px"
            lineHeight="1"
          >
            your new personal assistant.
          </Text>
        </Box>
        <LoginWithGoogle />
        <Box mt="30px">
          <Text
            color="white"
            as="span"
            fontSize={["12px", "12px", "12px", "16px", "16px"]}
            fontWeight="500"
            lineHeight="1.2"
            opacity="0.5"
          >
            By clicking ‘Continue with Google’, you acknowledge that you have
            read and understood, and agree to clutch’s{" "}
          </Text>
          <Text
            color="white"
            as="span"
            fontSize={["12px", "12px", "12px", "16px", "16px"]}
            fontWeight="500"
            lineHeight="1.2"
            opacity="0.8"
            textDecoration="underline"
          >
            <a href="/terms-and-conditions" target="_blank" rel="noreferrer">
              Terms and Conditions
            </a>
          </Text>
          <Text
            color="white"
            as="span"
            fontSize={["12px", "12px", "12px", "16px", "16px"]}
            fontWeight="500"
            lineHeight="1.2"
            opacity="0.5"
          >
            {" "}
            and{" "}
          </Text>
          <Text
            color="white"
            as="span"
            fontSize={["12px", "12px", "12px", "16px", "16px"]}
            fontWeight="500"
            lineHeight="1.2"
            opacity="0.8"
            textDecoration="underline"
          >
            <a href="/privacy-policy" target="_blank" rel="noreferrer">
              Privacy Policy.
            </a>
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Login;
