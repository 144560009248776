// package imports
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

// render function
const StyledMarkdown = styled(ReactMarkdown)`
  h1 {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 700;
    margin: 40px 0 20px 0;
    color: white;
  }

  h2 {
    font-size: 24px;
    line-height: 1.2;
    margin: 20px 0;
    font-weight: 500;
  }

  h3 {
    font-size: 22px;
    line-height: 1.2;
    margin: 20px 0;
    font-weight: 500;
  }

  p {
    font-size: 18px;
    line-height: 1.5;
    margin: 10px 0;
  }
`;

export default StyledMarkdown;
