import axios from "axios";
const BASE_URL = "https://api.clutchai.xyz";

// 01 — Authenticate User by exchanging Google OAuth2.0 Access Token for clutch.ai JWT
// ----------
export const postAccessCode = async (accessCode) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/google/callback/`, {
      access_token: accessCode,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 02 — Validate User Invite Code
// ----------
export const validateInviteCode = async (inviteCode, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/verify_invite_code/`,
      {
        invite_code: inviteCode,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 03 — Send OTP to user's WhatsApp number
// ----------
export const sendOTPtoWhatsapp = async (phone, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/verify_whatsapp_number/`,
      {
        whatsapp_number: `+91${phone}`,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 04 — Verify User's OTP
// ----------
export const verifyOTP = async (otp, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/verify_otp/`,
      {
        otp: otp,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
