// package imports
import { useContext, useState } from "react";

// interface imports
import {
  Box,
  Flex,
  Image,
  Text,
  Input,
  Icon,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { AiOutlineArrowRight } from "react-icons/ai";

// utility imports
import AuthContext from "../../utils/auth";
import { validateInviteCode } from "../../api/auth";

const Waitlist = () => {
  const [inviteCode, setInviteCode] = useState(); // state variable to store invite code
  const authContext = useContext(AuthContext); // access to auth context provider
  const toast = useToast(); // initialise toasts

  // API call to verify invite code
  const verficiationHandler = async () => {
    try {
      await validateInviteCode(inviteCode, authContext.token);
      authContext.verifyUser();
      window.location = "/connect-whatsapp";
    } catch (err) {
      console.log(err);
      toast({
        title: "An error occurred.",
        description: err.response.data.detail,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  // render function
  return (
    <Flex width="100vw" minHeight="100vh" bg="#040F13">
      <Flex
        flexDir="column"
        m="auto"
        width={["350px", "350px", "350px", "600px", "600px"]}
        bg="#1A2327"
        p={["30px", "30px", "30px", "50px", "50px"]}
        borderRadius="20px"
      >
        <Image
          src="https://i.imgur.com/0Rz2lMQ.png"
          width={["130px", "130px", "130px", "180px", "180px"]}
          height="fit-content"
        />
        <Box mt="30px">
          <Text
            color="white"
            as="span"
            fontSize="32px"
            fontWeight="600"
            letterSpacing="-0.5px"
            lineHeight="1"
            opacity="0.8"
          >
            enter your invite code.
          </Text>
        </Box>
        <Flex flexDir="row" mt="20px">
          <Input
            placeholder="waitlist code"
            size="lg"
            color="white"
            mr="10px"
            width="320px"
            fontWeight="600"
            onChange={(e) => setInviteCode(e.target.value)}
          />
          <IconButton
            size="lg"
            bg="white"
            color="#1A2327"
            icon={<Icon as={AiOutlineArrowRight} />}
            onClick={verficiationHandler}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Waitlist;
