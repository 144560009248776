import axios from "axios";
const BASE_URL = "https://api.clutchai.xyz";

// 01 — Get User Details for Dashboard
// ----------
export const getUserDetails = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/users/me/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
