// package imports
import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";

// interface imports
import App from "./App";
import "./index.css";

// utility imports
import { AuthContextProvider } from "./utils/auth";

// render function
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider>
    <BrowserRouter>
      <GoogleOAuthProvider clientId="522671447921-ggt3lp88lrs28bbbtkk1c1us4p0tt90a.apps.googleusercontent.com">
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </ChakraProvider>
);
