// interface imports
import {
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
  Button,
} from "@chakra-ui/react";

const Docs = () => {
  // render function
  return (
    <Flex width="100vw" minHeight="100vh" bg="#040F13">
      <Flex
        flexDir="column"
        width={["80vw", "80vw", "80vw", "55vw", "55vw"]}
        m="50px auto"
      >
        <Image
          src="https://i.imgur.com/9GlM093.png"
          width="100%"
          height="fit-content"
          borderRadius="20px"
        />
        <Text
          color="white"
          fontSize={["54px", "54px", "54px", "64px", "64px"]}
          fontWeight="800"
          mt="40px"
          lineHeight="1"
        >
          introducing, clutch.ai
        </Text>
        <Flex flexDir="row" alignItems="center" mb="40px" mt="20px">
          <Image
            src="https://avatars.githubusercontent.com/u/45586386?v=4"
            boxSize="40px"
            borderRadius="100%"
          />
          <Text color="white" fontSize="24px" fontWeight="500" ml="15px">
            by Parth Sharma
          </Text>
        </Flex>
        <Text
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="20px auto"
        >
          Imagine having a personal assistant who you can text anything,
          anytime, anywhere — and they’re guaranteed to come in clutch. That’s
          what the vision is for clutch. In a world with Siri, Alexa, ChatGPT,
          and countless other agents, this is undoubtedly a tall order — but
          it’s also a necessary one. Siri can be good at automation – but how
          creative is it? Or reliable unless you’re alone in a room without any
          noise? ChatGPT is extremely smart, but it’s just Google Search on a
          lot of caffeine. It’s also painfully bad at finding real-time
          information.
        </Text>
        <Text
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="20px auto"
        >
          That’s how clutch was born — we wanted to create an assistant that can
          take care of anything that you throw at it.
        </Text>
        <Text
          color="white"
          fontSize="40px"
          fontWeight="600"
          m="80px 0 0 0"
          lineHeight="1"
        >
          how do i use clutch?
        </Text>
        <Text
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="20px auto"
        >
          In 2023, everyone has a personal chat with themselves on WhatsApp.
          It’s known by many names — “me”, “name”, “stuff”, “temp” (the list
          goes on) — but there’s one thing that’s common for all of our groups.
          Scroll through the chat and you’ll see the remains of reminders you
          never knew existed, shopping lists hidden in obscurity, website links
          you promised to check out later, financial transactions you forgot
          about, and much more.
        </Text>
        <Text
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="20px auto"
        >
          That’s why clutch is a WhatsApp bot. We wanted to turn your pinned
          chat on WhatsApp into a hyperintelligent personal assistant that never
          forgets anything that you tell it. So — just pin your chat with clutch
          and text it like you would yourself — clutch will take care of the
          rest.
        </Text>
        <Text
          color="white"
          fontSize="40px"
          fontWeight="600"
          m="80px 0 0 0"
          lineHeight="1"
        >
          what can clutch do?
        </Text>
        <Text
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="20px auto"
        >
          Ideally — anything. This is still a private beta, so we’d love for you
          to tell us about additional use cases that we need to solve for. For
          now, we’ve split tasks into four main categories:
        </Text>
        <Text
          color="whiteAlpha.600"
          fontSize="32px"
          fontWeight="600"
          m="40px 0 0 0"
          lineHeight="1"
        >
          long form context
        </Text>
        <Text
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="20px auto"
        >
          Clutch is like your mom — neither of them will never forget a thing.
          Over time as you use clutch, it learns from the information that you
          give it to create a mindmap of curated information that can readily be
          looked up.
        </Text>
        <UnorderedList
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="10px 0"
          spacing={4}
        >
          <ListItem>
            Have a couple of meetings today? Just text the meeting notes to
            clutch, and then forget about it. You can ask it to summarize the
            meeting later and highlight any action items you might have left to
            do.
          </ListItem>
          <ListItem>
            Someone telling you about the best clubs to hit in Goa? Text it to
            clutch and just ask it for recommendations the next time you land in
            Goa.
          </ListItem>
          <ListItem>
            Found a great website to watch F1 races on? Just text it to clutch
            and you’ll never have to hunt down the link again.
          </ListItem>
          <ListItem>
            Text clutch whenever you make a transaction — and you can ask clutch
            for a spending report at the end of each day!
          </ListItem>
        </UnorderedList>
        <Text color="white" fontSize="24px" lineHeight="1.6" m="20px auto">
          Obviously, these are just examples to illustrate the idea of long form
          context (or memory). You can basically treat clutch as your personal
          notes app and it will learn from the information that you give it.
        </Text>

        <Text
          color="whiteAlpha.600"
          fontSize="32px"
          fontWeight="600"
          lineHeight="1"
          m="40px 0 0 0"
        >
          short form context
        </Text>
        <Text
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="20px auto"
        >
          There’s another way that you can use memory as short form context — to
          save lists. You can “Add” items to your reading lists/watch
          lists/shopping lists, and clutch will send you everything you’ve added
          to your list every Saturday morning — so you can use the weekend to
          catchup on stuff you’ve archived during the week. And yes, this
          extends to todo lists as well — which clutch reminds you of every
          morning once you’re awake.
        </Text>

        <Text
          color="whiteAlpha.600"
          fontSize="32px"
          fontWeight="600"
          m="40px 0 0 0"
          lineHeight="1"
        >
          time-based automation
        </Text>
        <Text
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="20px auto"
        >
          Ever wished someone just reminded you to do stuff? Yeah, so do we. So
          you can just text clutch — hey, remind me to call X in 2 hours — and
          clutch will be there in 2 hours to remind you.
        </Text>

        <Text
          color="whiteAlpha.600"
          fontSize="32px"
          fontWeight="600"
          m="40px 0 0 0"
          lineHeight="1"
        >
          information retreival
        </Text>
        <Text
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="20px auto"
        >
          Obviously, a personal assistant would be pretty dumb if they couldn’t
          retrieve information. So, clutch comes with all of the features of
          ChatGPT baked in (creative requests, information retrieval for
          specific questions) — as well as real-time information processing! So
          no more running up against the December 2022 training boundary — you
          can ask clutch about stuff like live stock prices, match scores, and
          much more!
        </Text>

        <Text
          color="whiteAlpha.600"
          fontSize="32px"
          fontWeight="600"
          m="40px 0 0 0"
          lineHeight="1"
        >
          connections
        </Text>
        <Text
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="20px auto"
        >
          This is a feature that’s coming very soon — but you’ll soon be able to
          compose and send emails, change slack statuses, and much more –
          straight from clutch.
        </Text>
        <Text
          color="white"
          fontSize="40px"
          fontWeight="600"
          m="80px 0 0 0"
          lineHeight="1"
        >
          what next?
        </Text>
        <Text
          color="white"
          fontSize={["20px", "20px", "20px", "24px", "24px"]}
          lineHeight="1.6"
          m="20px auto"
        >
          We hope that you have as much fun using clutch as we had building it.
          We're constantly going to be shipping new features and use cases — and
          so, we'd absolutely love to hear from you about how you use clutch and
          what you'd like to see next!
        </Text>
        <Button mt="80px" width="fit-content" size="lg" as="a" href="/">
          Back to Dashboard
        </Button>
      </Flex>
    </Flex>
  );
};

export default Docs;
