// interface imports
import { Flex, Icon, Tag, TagLabel, TagLeftIcon, Text } from "@chakra-ui/react";
import { BiCheckCircle } from "react-icons/bi";

const Connection = ({
  connectionTitle,
  connectionDesc,
  connectionLogo,
  connectionId,
}) => {
  return (
    <Flex flexDir="column" mt={["30px", "30px", "30px", "50px", "50px"]}>
      <Text
        fontSize={["14px", "14px", "14px", "16px", "16px"]}
        fontWeight="600"
        color="white"
        opacity="0.8"
        textTransform="uppercase"
      >
        {connectionTitle}
      </Text>
      <Flex
        flexDir={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        bg="#1A2327"
        borderRadius="10px"
        width="100%"
        color="white"
        p={["15px", " 15px", "15px", "20px 15px", "20px 15px"]}
        mt="12px"
        justifyContent="space-between"
      >
        <Flex flexDir="row">
          <Icon
            as={connectionLogo}
            boxSize={["16px", "16px", "16px", "20px", "20px"]}
            m={[
              "0 20px 0 0px",
              "0 20px 0 0px",
              "0 20px 0 0px",
              "0 30px 0 10px",
              "0 30px 0 10px",
            ]}
          />
          <Flex flexDir="column">
            <Text
              fontSize={["18px", "18px", "18px", "20px", "20px"]}
              fontWeight="600"
              lineHeight="1"
            >
              {connectionId}
            </Text>
            {connectionDesc ? (
              <Text
                fontSize={["12px", "12px", "12px", "15px", "15px"]}
                mt="10px"
                opacity="0.8"
                fontWeight="500"
                lineHeight={["1.5", "1.5", "1.5", "1", "1"]}
              >
                {connectionDesc}
              </Text>
            ) : null}
          </Flex>
        </Flex>
        <Tag
          borderRadius="20px"
          bg="#5CB85C"
          color="white"
          size="md"
          p="2px 10px"
          alignItems="center"
          height="fit-content"
          width="fit-content"
          mb={["20px", "20px", "20px", "0", "0"]}
        >
          <TagLeftIcon
            as={BiCheckCircle}
            boxSize={["12px", "12px", "12px", "14px", "14px"]}
            mr="10px"
          />
          <TagLabel
            fontSize={["12px", "12px", "12px", "14px", "14px"]}
            fontWeight="600"
            mr="5px"
          >
            ACTIVE
          </TagLabel>
        </Tag>
      </Flex>
    </Flex>
  );
};

export default Connection;
