// package imports
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// interface imports
import Login from "./views/auth/Login";
import Dashboard from "./views/dashboard/Dashboard";
import Waitlist from "./views/auth/Waitlist";
import ConnectWA from "./views/auth/ConnectWA";
import Docs from "./views/info/Docs";

// utility imports
import AuthContext from "./utils/auth";
import Legal from "./views/info/Legal";
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "./utils/legal";

const App = () => {
  const authContext = useContext(AuthContext); // access to auth context

  // render function
  return (
    <Routes>
      {/* HOME */}
      <Route
        exact
        path="/"
        element={
          authContext.isLoggedIn ? (
            authContext.userVerified ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/waitlist" />
            )
          ) : (
            <Login />
          )
        }
      />

      {/* DASHBOARD */}
      <Route
        path="/dashboard"
        element={authContext.isLoggedIn ? <Dashboard /> : <Navigate to="/" />}
      />

      {/* AUTH */}
      <Route
        path="/waitlist"
        element={
          authContext.isLoggedIn ? (
            !authContext.userVerified ? (
              <Waitlist />
            ) : (
              <Navigate to="/connect-whatsapp" />
            )
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/connect-whatsapp"
        element={authContext.isLoggedIn ? <ConnectWA /> : <Navigate to="/" />}
      />

      {/* DOCS */}
      <Route path="/docs" element={<Docs />} />
      <Route
        path="/privacy-policy"
        element={<Legal markdown={PRIVACY_POLICY} />}
      />
      <Route
        path="/terms-and-conditions"
        element={<Legal markdown={TERMS_AND_CONDITIONS} />}
      />
    </Routes>
  );
};

export default App;
